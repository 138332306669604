<template>
  <b-card
      style="width:100%"
  >
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >

        <b-row>

<!--  盘点编号  -->
          <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="指令编号:"
                label-for="name"
                label-size="sm"
            >
              <b-form-input
                  v-model="condition.produce_no"
                  class="d-inline-block mr-1"
                  size="sm"
              />
            </b-form-group>


          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="生产仓库"
                label-for="warehouse_name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="warehouse_name"
                  size="sm"
                  v-model="condition.warehouse_name"
                  @click="showFromWHModal()"
                  placeholder="请选择仓库"
                  readonly
              />
            </b-form-group>
          </b-col>

          <b-col   cols="12"
                   md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="状态:"
              label-for="produce_status"
              label-size="sm"
          >
            <v-select
                id="allot_type"
                :options="getCodeOptions('produce_status')"
                :clearable="true"
                v-model = "condition.status"
                class="select-size-sm"
                placeholder="选择生产状态"
                :reduce="option => option.value"
            />
          </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="申请人"
                label-for="applier_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="duty_name"
                  size="sm"
                  v-model="condition.applier_name"
                  @click="showPeopleModal()"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="交货时间"
                label-for="true_last_date"
                label-size="sm"
            >
              <flat-pickr
                  v-model="condition.true_last_date"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="3"
          >
            <b-button
                variant="outline-primary"
                type="submit"
                class="mr-1"
            >
              <span class="align-right">查询</span>
            </b-button>

            <b-button
                variant="outline-primary"
                type="reset"
            >
              <span class="align-right">重置</span>
            </b-button>

          </b-col>
        </b-row>
    </b-form>

<!-- 仓库   -->
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectWHFromPeople"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择仓库'"
        ref="fromWHModal"
    >
      <WareHouse-Select
          ref="whSelect" v-on:table="fromChildren($event,'from')"
      >
      </WareHouse-Select>
    </b-modal>

    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectPeople"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择申请人'"
        ref="peopleModal"
    >
      <user-select
          ref="peopleSelect" v-on:table="fromChildren($event,'user')"
      >
      </user-select>
    </b-modal>

  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRef, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import ProductSelect from '@/views/apps/product/product-purchase/ProductSelect'
import produceorderStore from "@/views/apps/produceorder/produceorderStore";
import UserSelect from "@/views/apps/user/user-modal/UserSelect";

export default {
  components: {
    WareHouseSelect,
    ProductSelect,
    CompanySelect,
    UserSelect,
  },
  props: {
    warehouseId: {
      type: Number,
      default: 0,
    },
    role:{
      type:Object,
    }
  },
  setup(props, { emit }) {

    const toast = useToast()
    // Register module
    if (!store.hasModule('produceorder')) store.registerModule('produceorder', produceorderStore)

    onUnmounted(() => {
      if (store.hasModule('produceorder')) store.unregisterModule('produceorder')
    })

    onMounted(() => {
      state.condition = store.getters['produceorder/getCondition']
    })

    const state = reactive({
      modalName: '',
      condition: {
      },
      singleModal: null,
    })

    const methods = {

      showPeopleModal(){
        this.$refs['peopleModal'].show()
      },
      onSelectPeople() {
        let returnData = {}
        if(this.$refs.peopleSelect.getSelected().length > 0){
          returnData = this.$refs.peopleSelect.getSelected()[0]
          this.condition.applier_id = returnData.user_id
          this.condition.applier_name = returnData.full_name
          this.$forceUpdate()
        }
      },
      refreshTable  (){
        emit('refreshTable')
      },

      resetCondition() {
        store.commit('produceorder/resetCondition')
        methods.refreshTable()
      },
      showFromWHModal(){
        this.$refs['fromWHModal'].show()
      },
      onSelectWHFromPeople(){
        let returnData = {}
        if(this.$refs.whSelect.getSelected().length > 0){
          returnData = this.$refs.whSelect.getSelected()[0]
          //判斷是否已添加材料明细
          this.condition.warehouse_id = returnData.id
          this.condition.warehouse_name = returnData.warehouse_name
          this.$forceUpdate()
        }
      },
    }

    const fromChildren = function (data,modal) {
      if (modal=='user'){
        this.condition.applier_id = returnData.user_id
        this.condition.applier_name = returnData.full_name
        this.$forceUpdate()
      }
      if (modal=="from"){
        this.condition.warehouse_id = data.id
        this.condition.warehouse_name = data.warehouse_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    return {
      ...toRefs(state),
      ...methods,

      //filter
      getCodeOptions,
      fromChildren
    }
  },
}
</script>

<style scoped>

</style>
