<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <search @refreshTable=refetchData ></search>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />-->
              <b-button
                variant="primary"
                :to="{ name: 'apps-produceorder-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-produceorder-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(produce_type)="data">
          {{getCodeLabel('produce_type', data.item.produce_type)}}
        </template>

        <template #cell(attachments_sales)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_sales'"
                             :id="data.item.attachments_sales"
                             :object_type="'attachments_sales'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <template #cell(attachments_op)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_op'"
                             :id="data.item.attachments_op"
                             :object_type="'attachments_op'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'attachments'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <template #cell(true_good_qty)="data">
          {{data.item.true_good_qty}}(良)
          <br>
          {{data.item.true_bad_qty}}(不良)
        </template>

        <template #cell(status)="data">
         <!-- <b-badge
                  pill
                  :variant="`light-${getCodeColor('produce_status', data.item.status)}`"
          >-->
          {{getCodeLabel('produce_status', data.item.status)}}
         <!-- </b-badge>-->
        </template>

        <template #cell(create_time)="data">
          {{toDate(data.item.create_time)}}
        </template>
        <template #cell(start_date)="data">
          {{toDate(data.item.start_date)}}
        </template>
        <template #cell(purchase_last_date)="data">
          {{toDate(data.item.purchase_last_date)}}
        </template>

        <template #cell(true_last_date)="data">
          {{toDate(data.item.true_last_date)}}
        </template>

        <template #cell(update_time)="data">
          {{toDate(data.item.update_time)}}
        </template>

        <template #cell(memo)="data">
          {{data.item.memo}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" >
          <b-dropdown
            variant="link"
            no-caret

          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <div v-if="data.item.status == 1">
              <b-dropdown-item @click="submitCheck(data.item.produce_id)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">提交审核</span>
              </b-dropdown-item>

             <b-dropdown-item @click="submitCheckOk(data.item.produce_id)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-produceorder-edit', query: { id: data.item.id },params:{readonly:0} }" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>

            </div>


            <!--<div v-if="data.item.status == 2 &&user.role_id==6">-->
            <div v-if="data.item.status == 2  ">
              <b-dropdown-item @click="submitCheckNo(data.item.produce_id)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </div>

            <div v-if="data.item.status == 4 && user.role_id==6 ">
              <b-dropdown-item :to="{ name: 'apps-produceorder-edit', query: { id: data.item.id },params:{readonly:0} }" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>
            </div>

<!--            <div v-if="data.item.status == 5">
              <b-dropdown-item @click="checkcomplete(data.item.produce_id)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">验收完成</span>
              </b-dropdown-item>
            </div>-->

            <b-dropdown-item    @click="printProduceOrder(data.item.produce_id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">打印</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-produceorder-edit', query: { id: data.item.id },params:{readonly:1,statusItem:data.item.status} }" >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>



          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!-- 添加附件窗口 -->
    <b-modal
            id="fileModal"
            ok-title="确认"
            cancel-title="取消"
            centered
            size="xl"
            no-close-on-backdrop
            no-close-on-esc
            title="添加附件"
            ref="fileModal"
            @ok="submitInfo"
            @hidden="clearInput"
    >
      <div>
        <b-col cols="12">
          <label>备注</label>
          <b-form-textarea v-model="contentInfo"></b-form-textarea>
        </b-col>
        <br>
        <b-col cols="12">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1 required"

          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments_wh'"
                               :id="memoFile"
                               object_type="produce_order"
                               :object_id="0"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </div>
      <br>
    </b-modal>

    <b-modal
        id="pdfModal"
        centered
        title="PDF"
        ref="pdfModal"
        hide-footer
    >
      <attachment-upload theme="preview"
                         attachment_id="attachments"
                         :id="pdf_id"
                         :readonly="true"
                         v-if="pdf_id"
      />
      <div v-else>
        <p class="my-4">正在生成，请稍候!</p>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, toRefs} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import produceorderUseList from './produceorderUseList'
import produceorderStore from './produceorderStore'
import { useToast } from 'vue-toastification/composition'
import {getUserData} from '@/auth/utils'
import {useRouter} from "@core/utils/utils";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import Search from "@/views/apps/produceorder/Search";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    Search
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('produceorder/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    clearInput: function() {
      console.log("清空所有选项")
      this.uploadInfo = undefined
      this.contentInfo = ''
    },
  },
  data(){
    return{
      user:{},
      memoFile: '',
      contentInfo: '',
    }
  },
  setup() {
    // Register module
    const toast = useToast()
    const router = useRouter()

    if (!store.hasModule('produceorder')) store.registerModule('produceorder', produceorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceorder')) store.unregisterModule('produceorder')
    })

    const onUploaded = function (id, attachment, result) {
      this.uploadInfo = attachment
    }

    const submitCheck = function (id){
      if(confirm("确认提交运营主管审核？")) {
        store.dispatch('produceorder/submitcheck', {id: id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const submitCheckOk = function (id){
      if(confirm("确认删除此生产单？")) {
        store.dispatch('produceorder/submitcheckproduct', {id: id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }


    const submitCheckNo = function (id){
      if(confirm("确认驳回该生产单？")) {
        store.dispatch('produceorder/submitcheckno', {id: id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }
    const checkcomplete = function (id){
      this.produce_id = id;
      this.$refs['fileModal'].show()
    }

    const submitInfo = function () {
      console.log("uploadInfo:============")
      console.log(this.uploadInfo)
      if(this.uploadInfo==undefined){
        toast.error("请选择附件")
        return false
      } else{
        let fileArr = []
        for (let i = 0; i < this.uploadInfo.length; i++) {
          fileArr.push(this.uploadInfo[i].attachment_id)
        }
        if(confirm("确认验收完成？")) {
          store.dispatch('produceorder/checkcomplete', {
            id: this.produce_id,
            'attachmentsWh': JSON.stringify(fileArr)
                .replace(']', '')
                .replace('[', ''),
            'memoWh': this.contentInfo,
          })
              .then(res => {
                if (res.data.code == 0) {
                  toast.success("操作成功")
                  window.location.reload()
                } else {
                  toast.error(res.data.data)
                }
              })
        }
      }


    }

    const state = reactive({
      condition:{
      },
      pdf_id:undefined,
      pdfModal:undefined,
    })

    const printProduceOrder = function (data){
      //点击打印时，先弹出选择店铺名称弹框  传一个 店铺ID或者直接传一个店铺Name
      this.outbound_warehouse = data
      store.dispatch('produceorder/printProduceOrder',{
        produce_id:data
      }).then(res => {
        if (res.data.code === 0) {
          state.pdfModal.show()
          state.pdf_id = res.data.data.id
          this.$router.push({name: 'apps-produceorder-list'});
        } else {
          toast.error(res.data.data)
        }
      })
    }






    const {
      searchList,

      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = produceorderUseList(
            {
              produce_id:router.route.value.query.produce_id
            }
    )

    return {
      searchList,
      submitInfo,
      submitCheckNo,
      submitCheck,
      submitCheckOk,
      onUploaded,
      checkcomplete,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      printProduceOrder,
      ...toRefs(state),
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
